<template>
   <el-container class="recordadd p15">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>模板管理</el-breadcrumb-item>
       <el-breadcrumb-item v-if="formInline.menu_id">编辑模块</el-breadcrumb-item>
       <el-breadcrumb-item v-else>新增模块</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
          
          <el-button  class="back_btn"  size="medium" plain @click="$router.back()">返回</el-button>
        </div>
    </el-header>
    <el-main  class="pt0">
      <el-form  :inline="true" status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="模块名称：" size="medium" prop="name" :rules="[{required: true,message: '请输入模块名称', trigger: 'blur' }]">
           <el-input placeholder="请输入模块名称" v-model="formInline.name" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
           <el-form-item class="w" label-width="28%" label="模块图标：" size="medium" >
           <el-input placeholder="请输入模块图标" v-model="formInline.icon" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
             <el-form-item class="w" label-width="28%" label="模块颜色：" size="medium" >
                 <el-input placeholder="请输入模块颜色" v-model="formInline.color" class="input-with-select">
                 </el-input>
             </el-form-item>
         </el-col>
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="菜单模块连接：" size="medium" >
           <el-input placeholder="请输入模块路由连接" v-model="formInline.path" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="22" class="tl" :offset="1">
           <el-button  class="" size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
        </el-col>
     </el-row>
     
 </el-form>
    </el-main>
   </el-container>
</template>
<script>
import axios from '../../../../util/api'
import { local,session} from '../../../../util/util'
export default {
  data () {
    return {
        user:'',
      formInline: {
         menu_id:'',
         page_id:"",
        menu_parent_id: '0',
        menu_name: '',
        menu_logo: '',
        menu_url:'',
        menu_level: '1',
        audit_use_sate:''
      },
      Options2:[{ label: "否", value: "1" },{ label: "是", value: "2" }],
    }
  },
   created () {
       this.user = local.get('user');
       this.formInline.id =session.get('params').id?String(session.get('params').id):'';
       if(this.formInline.id){this.init()}
  },
  methods: {
       init () {
          axios.get("/pc/menu/singleAppMenu",{id:String(this.formInline.id)}).then(v => {
              this.formInline = v.data.menu;
          });
       },
         submitForm(formInline){
         this.$refs[formInline].validate((valid) => {
        if (valid) {
            if(this.formInline.id){
                 axios.put('/pc/menu/updateAppMenu', this.formInline).then((v) => {
                this.$router.back()
             })
            }else{
                axios.post('/pc/menu/saveAppMenu', this.formInline).then((v) => {
              this.$router.back()
              })
            }
        
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      
      },
  }
}
</script>
<style lang="scss" scoped>
.recordadd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
   /deep/ .no-header .has-gutter{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload .el-upload-list__item{width: 160px;height: 160px;float: left;margin-right: 10px;
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}}
      /deep/ .disabled .el-upload--text {
    display: none !important;
}
}

</style>